import React, { useContext, useState } from 'react';
import { Pane, Button, Badge, DownloadIcon, LockIcon, Table, Tooltip, Checkbox } from 'evergreen-ui';
import FinanceiroContext from '../../../../context/FinanceiroContext';
import PlanilhaComissaoPDF from '../../../../pdf/PlanilhaDeComissao';
import ShowDocument from '../../../../app/showDocument';
import updateComfirmPgt from '../../../../apis/updateComfirmPgt';
import getProcessById from '../../../../apis/getProcessById';

function ColTable(props) {
    
  return (
    <Table.TextCell maxWidth={props.width}>
      <div className={`mb7 color101840 `}>{props.title}</div>

      <span className='fw400 color696F8C'>
        {props.subtitle}
      </span>
    </Table.TextCell>
  )
};

function ColMapPorcentagens({ users }) {
  return (
    <Table.Row height={"auto"}>
      <Table.TextCell className="p10">
        <div className='flex mb10'>
          <div className='mr17 w195'>Nome completo </div>
          <div className="mr42 w97">Porcentagem</div>
          <div className="mr42 w68">Desconto</div>
          <div className='w97'>Valor</div>
        </div>

        {users?.map((user) => (
          <div className='flex mb5' key={user.name}>
            <div className='wsNormal mr17 w195 fw400'>
              {user.name}
            </div>
            <div className="mr42 w97 fw400">
              {user.porcentagem_comissao} %
            </div>
            <div className="mr42 w68 fw400">
              {user.desconto || "---"}
            </div>
            <div className="fw400 w97 wsNormal">
              R$ {user.valor_real}
            </div>
          </div>
        ))}
      </Table.TextCell>
    </Table.Row>
  )
};

function ColMapParcelas({parcelas, integral}) {
    const { setProcessSelect } = useContext(FinanceiroContext);
    const [checkedComfirmaPgt, setCheckedComfirmaPgt] = useState(
        parcelas.map(parcela => parcela.comfirmacao_pagamento === 1)
    );

    const handleCheckboxChange = async (index, isChecked) => {

        const updatedChecked = [...checkedComfirmaPgt];
        updatedChecked[index] = isChecked; 
        setCheckedComfirmaPgt(updatedChecked);

        const parcela_id = parcelas[index].id; 
        await updateComfirmPgt(parcela_id, isChecked);

        const dataProcess = await getProcessById(parcelas[index].processo_id);
        setProcessSelect(dataProcess);

    };
      
  // const { listaPeriodo } = useContext(FinanceiroContext);
  const token = localStorage.getItem('token')
  const [listaPeriodo] = useState([
    { label: 'Na assinatura do Recibo de Sinal', value: '1' },
    { label: 'Na retirada das certidões', value: '2' },
    { label: 'No ato da escritura do Imóvel', value: '3' },
    { label: 'Na entrega das chaves', value: '4' },
    { label: 'Na transferência de registros', value: '5' },
  ])

  const labelPeriodo = (value) => {
    
    if (!value) return ""
    return listaPeriodo.find((item) => item.value === value).label
  };

  console.log(parcelas);

    return (
        <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
                <div className='flex mb10'>
                    <div hidden={integral} className='mr35 w50'>Parcelas </div>
                    <div hidden={integral} className="mr44 w75">Valor</div>
                    {/* <div className="mr42 w160 mw200">Período do pagamento</div> */}
                    <div className="mr42 w120">Previsão de pagamento</div>
                    <div className="mr42 w60">Comprovante</div>
                    <div className="mr42 w120">Confirmar </div>

                </div>
                {parcelas.length === 0 && <div className='mb5'>---</div>}
                {parcelas?.map((parcela, index) => (
                    <div className='flex mb5' key={index}>
                        <div hidden={integral} className='wsNormal mr35 w50 fw400 mt6'>
                            {index + 1}
                        </div>
                        <div hidden={integral} className="mr44 w75 fw400 mt6">
                            {parcela.valor_parcela}
                        </div>
                        {/* <div className="mr42 w160 fw400 mw200 mt6">
                            {labelPeriodo(parcela.periodo_pagamento)}
                        </div> */}
                        <div className=" mr42 fw400 w120 mt6">
                            {parcela.data_comissao}
                        </div>
                        <div className=" mr42 fw400 w60 mt6">
                            {parcela.comprovante_pagamento ?
                                <Button appearance="primary" onClick={() => ShowDocument(parcela.comprovante_pagamento.id, '', token)}>Ver</Button>
                                : "---"
                            }
                        </div>
                        <div className="">
                            <Checkbox
                                className='checkbox representante-uniao-estavel'
                               
                                checked={checkedComfirmaPgt[index]}
                                onChange={(e) => handleCheckboxChange(index, e.target.checked)  }
                                //refreshProcess={refreshProcess}
                               // disabled={loading}
                            />
                        </div>
                    </div>
                ))}
            </Table.TextCell>
        </Table.Row>
    )
};

export default function Comissao() {
  const {
    processSelect,
    listLaudemios,
  } = useContext(FinanceiroContext);

  const [token] = useState(localStorage.getItem('token'));
  const msgCampoVazio = '---'
  const { imovel } = processSelect;
  const { comissao, vendedores } = processSelect?.imovel;
  const planilha = comissao?.documentos.data.filter((documento) => documento.identifica_documento === "comissão");
  const opcionistas = comissao?.corretores_opicionistas_comissao.map((user) => ({
    ...user, 'porcentagem_comissao': user.porcentagem_comissao_opcionista
  }));
  const corretores = comissao?.corretores_vendedores_comissao.map((user) => ({
    ...user, 'porcentagem_comissao': user.porcentagem_comissao_corretor
  }));
  const gerentesGerais = imovel.comissao_gerente_gerais;
  const gerentes = imovel.comissao_gerentes;

  console.log("Imovel: ", imovel);
  console.log("Comissão: ", comissao);
  console.log('Planilha ', planilha);

  const percentualOpcionistas = (type) => {
    switch (opcionistas[0]?.tipo_laudo_opcionista) {
      case "simples":
        return type === "total" ? "16%" : 'Simples';
      case "com_chave":
        return type === "total" ? "18%" : 'Com chave';
      case "exclusividade":
        return type === "total" ? "20%" : 'Exclusividade';
      case "lançamento":
        return type === "total" ? "8%" : 'Lançamento';
      default:
        return "";
    }
  };

  //lê os laudemios retornados em imovel
  const laudemiosEnviados = imovel.laudemios.map(laudemio => parseInt(laudemio.valor_laudemio));

  //Comparar imovel a uma lista de todos os laudemios "tipoLaudemio" e retorna o id e nomes para exibir
  const laudemioValorToName = listLaudemios.filter((laudemio) => laudemiosEnviados.includes(laudemio.id));
  const laudemiosSemNome = imovel.laudemios.filter((laudemio) => laudemio.tipo_laudemio === "2" || laudemio.tipo_laudemio === "1");
  laudemioValorToName.push(...laudemiosSemNome);

  const capitalize = (text) => {
    if (!text) return ""
    const words = text.split(" ").map((word) => (
      word[0].toUpperCase() + word.substring(1)
    ))
    return words.join(" ");
  };

  const arrVendedores = vendedores.data
  const integral = comissao?.comissao === 'integral'

  const returnNameVendedor = (id, campo) => {
    const saler = arrVendedores.find((user) => user.id === id);

    if (campo === 'name') {
      if (saler.name) return saler.name
      else return saler.razao_social
    } else {
      return saler.cpf_cnpj
    }
  };

  return (
    <div className="detadelhes-sidesheet">
      {comissao === null
        ?
        <Table.Row>
          <Table.TextCell className="historicoStatus" width={150}>
            <Table.TextCell className="fontStatus">
              <span>Nenhuma Comissão foi cadastrada no momento.</span>
            </Table.TextCell>
          </Table.TextCell>
        </Table.Row>

        :
        <>
          <Pane>
            {planilha.length > 0
              ?
              <div>
                <h2 className="f18 fw600 mb16">Planilha de Comissão</h2>
                <p className='fw400 f12 mb16'>Faça aqui o download da Planilha inserida no sistema através do seu upload do arquivo .</p>
                {planilha.map((documento) =>
                  <div key={documento.id}>
                    <Button className='mb10 w250' onClick={(e) => [ShowDocument(documento.id, 'documento', token), e.preventDefault()]}>
                      <DownloadIcon size={12} color="#474D66" marginRight={10} /> Download {documento?.tipo_documento?.nome.toLowerCase()}
                    </Button>
                  </div>
                )}
              </div>
              :
              comissao.verificar_enviar_planilha === 1
                ? <Button appearance="primary" intent="primary" onClick={(e) => PlanilhaComissaoPDF(imovel)}><DownloadIcon size={12} color="#fff" marginRight={10} /> Download da Planilha de Comissão</Button>
                :
                <div>
                  <Tooltip content="O download da Planilha será liberado após revisão da Equipe de Apoio à vendas.">
                    <Button appearance="primary" intent="primary" className='btnDisable'><LockIcon size={12} color="#fff" marginRight={10} /> Download da Planilha de Comissão</Button>
                  </Tooltip>
                </div>

            }

            <React.Fragment>
              <h2 className="subtitle subtitle-table">Total</h2>
            </React.Fragment>
          </Pane>

          <Table.Row>
            <ColTable title={"Tipo"} width="85px" subtitle={comissao.comissao === 'integral' ? "Integral" : "Parcelada"} />
            <ColTable title={"Comissão total"} width="140px" subtitle={comissao?.valor_comissao_total || ''} />
            <ColTable title={"Deduções"} subtitle={comissao.deducao !== null ? comissao.deducao : msgCampoVazio} />
          </Table.Row>

          <ColMapParcelas parcelas={comissao.parcelas_empresa} integral={comissao.comissao === "integral"} />

          <Pane>
            <React.Fragment>
              <h2 className="subtitle subtitle-table">Quem vai pagar</h2>
            </React.Fragment>
          </Pane>

          <Table.Row height={"auto"}>
            <Table.TextCell className="p10">
              <div className='flex mb10'>
                <div hidden={integral} className='mr35 w60'>Parcelas</div>
                <div className="mr44 w229">Vendedor</div>
                <div className="mr42 w97">CPF</div>
                <div className="mr42 w97">Valor em R$</div>
              </div>
              {comissao?.vendedor_responsavel_pagamento.length === 0 && <div className='mb5'>---</div>}
              {comissao?.vendedor_responsavel_pagamento?.map((vendedor) => (
                <div className='flex mb5' key={vendedor.usuario_id}>
                  <div hidden={integral} className='wsNormal mr35 w60 fw400'>
                    {vendedor.numero_parcela}
                  </div>
                  <div className="mr44 w229 fw400">
                    {vendedor.usuario_id && returnNameVendedor(vendedor.usuario_id, 'name')}
                  </div>
                  <div className="mr42 w97 fw400">
                    {vendedor.parcela_valor && returnNameVendedor(vendedor.usuario_id)}
                  </div>
                  <div className="mr42 w97 fw400">
                    {vendedor.parcela_valor}
                  </div>
                </div>
              ))}
            </Table.TextCell>
          </Table.Row>
          <Table.Row>
            <ColTable title={"Observação enviada pelo PosVenda"} subtitle={"" || msgCampoVazio} />
          </Table.Row>


          <Pane>
            <React.Fragment>
              <h2 className="subtitle subtitle-table">Líquida</h2>
            </React.Fragment>
          </Pane>

          <Table.Row>
            <ColTable title={"Forma de Pagamento"} subtitle={capitalize(comissao?.liquida) || msgCampoVazio} />
            <ColTable title={"Comissão líquida"} subtitle={comissao?.valor_comissao_liquida || msgCampoVazio} />
          </Table.Row>

          {(!planilha || planilha.length === 0) &&
            <Pane>
              <Pane /*hidden={gerentesGerais.length <= 0}*/>
                <h2 className="subtitle subtitle-table">Comissão e porcentagens</h2>
              </Pane>

              <Pane className='mt16'>
                <React.Fragment>
                  <span className="mr20">Gerentes Gerais</span> {gerentesGerais.length > 0 && <Badge color="blue">{`Total de ${comissao?.valor_comissao_gg}%`}</Badge>}
                </React.Fragment>
                {imovel.loja.gerente_geral_id === null &&
                  <div className='color101840 fw400 mt15'>
                    A loja {imovel.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.
                  </div>}
              </Pane>

              {gerentesGerais.length > 0 ?
                <Pane className='mt16'>
                  <ColMapPorcentagens users={gerentesGerais} />
                </Pane>
                : <div className="fw400 f12 mt10">A loja {imovel.loja.nome} não possui nenhum GG no momento, mas podem ter sido registrados um ou mais profissionais para receber a comissão equivalente.</div>
              }

              {gerentes.length > 0 &&
                <Pane>
                  <Pane className={"mt40"}>
                    <React.Fragment>
                      <span className="mr20">Gerentes</span> <Badge color="blue">Total de {comissao?.valor_comissao_gerente}%</Badge>
                    </React.Fragment>
                  </Pane>

                  <Pane className='mt16'>
                    <ColMapPorcentagens users={gerentes} />
                  </Pane>
                </Pane>
              }

              {corretores.length > 0 &&
                <Pane>
                  <Pane className={
                    gerentesGerais.length > 0 || gerentes.length > 0
                      ? 'mt40'
                      : 'mt16'
                  }
                  >
                    <React.Fragment>
                      <span className="mr20">Corretores vendedores</span> <Badge color="blue">Total de {corretores[0]?.total_comissao_corretor}%</Badge>
                    </React.Fragment>
                  </Pane>

                  <Pane className='mt16'>
                    <ColMapPorcentagens users={corretores} />
                  </Pane>
                </Pane>
              }

              {opcionistas.length > 0 &&
                <Pane>
                  <Pane className='mt40'>
                    <React.Fragment>
                      <span className="mr20">Opcionistas</span> <Badge color="blue">Total de {percentualOpcionistas("total")}</Badge>
                    </React.Fragment>
                  </Pane>
                  <Table.Row className='mt16'>
                    <ColTable title={"Tipo de Laudo"} subtitle={percentualOpcionistas("tipo")} />
                  </Table.Row>

                  <Pane className='mt5'>
                    <ColMapPorcentagens users={opcionistas} />
                  </Pane>

                </Pane>
              }
            </Pane>
          }
          <Pane>
            <React.Fragment>
              <h2 className="subtitle subtitle-table">Observações</h2>
            </React.Fragment>
          </Pane>
          <Table.Row className='hAuto'>
            <Table.TextCell className="wsNormal hAuto">
              <div className='fw400 pb5 pt5'>
                {comissao.observacoes ? comissao.observacoes : msgCampoVazio}
              </div>
            </Table.TextCell>
          </Table.Row>
        </>
      }
    </div>
  )
}