import Axios from 'axios';
import Serve from '../app/serve';

async function getListaFinanceiroCsv(/*page,*/ filtro/*, ordenacao*/) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'lista_resumida_financeiro_csv', {
    responsavel_id: filtro.responsavel,
    // page,
    forma_pagamento_id: filtro.forma_pagamento,
    gerente_id: filtro.gerente?.id,
    cliente_id: filtro.cliente.id,
    logradouro: filtro.endereco,
    periodo_inicial: filtro.periodo_inicial,
    periodo_final: filtro.periodo_final,
    // ordenacao: ordenacao.valor,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (!res?.data?.processos) {
      data = false;
    }
    else if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false;
    } else {
      data = res.data.processos.data;
      console.log(data)
      //return data;
    }
  })

  return data;
};

export default getListaFinanceiroCsv;